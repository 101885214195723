"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DISCOUNT_MODES = exports.PLAN_DISCOUNT_MODES = exports.STANDARD_PLAN_DISCOUNT_MODES = exports.MATCH_LEVELS = exports.XERO_TIER_NAMES = exports.COMMON_TIER_NAMES = void 0;
exports.COMMON_TIER_NAMES = ['11-20', '21-50', '51-200', '201-500', '501-1000', '1001-5000', '>5000'];
exports.XERO_TIER_NAMES = __spreadArray(['2-10'], __read(exports.COMMON_TIER_NAMES), false);
exports.MATCH_LEVELS = ['exact', 'strong', 'weak', 'notFound', 'multiple'];
exports.STANDARD_PLAN_DISCOUNT_MODES = ['fixed_price', 'percent_discount', 'fixed_discount'];
exports.PLAN_DISCOUNT_MODES = __spreadArray(__spreadArray([], __read(exports.STANDARD_PLAN_DISCOUNT_MODES), false), ['per_unit'], false);
exports.DISCOUNT_MODES = ['split', 'percent_discount', 'percent_profit', 'per_plan'];
