"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IconButtonSizes = void 0;
exports.IconButtonSizes = {
    small: {
        currency: { height: 11, width: 10.5 },
        decimal: { height: 6.5, width: 12.5 },
        invalidSpend: { height: 8, width: 8 },
        scale: 0.5,
    },
    medium: {
        currency: { height: 22, width: 21 },
        decimal: { height: 13, width: 25 },
        invalidSpend: { height: 16, width: 16 },
        scale: 1,
    },
    large: {
        currency: { height: 44, width: 42 },
        decimal: { height: 26, width: 50 },
        invalidSpend: { height: 36, width: 36 },
        scale: 2,
    },
};
