"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkIfTo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_router_dom_1 = require("react-router-dom");
var general_1 = require("utils/general");
var StyledLink = (0, material_1.styled)(react_router_dom_1.Link, { shouldForwardProp: (0, general_1.omitProps)('underlined', 'fontWeight') })(function (_a) {
    var theme = _a.theme, _b = _a.fontWeight, fontWeight = _b === void 0 ? 'fontWeightMedium' : _b, underlined = _a.underlined;
    return ({
        color: theme.palette.text.secondary,
        fontWeight: theme.typography[fontWeight],
        textDecoration: underlined ? 'underline' : 'none',
        borderRadius: 2,
        ':hover': {
            color: theme.palette.text.primary,
            textDecoration: underlined ? 'underline' : 'none',
        },
        ':focus-visible': __assign({}, theme.focusVisibleStyles),
    });
});
var LinkIfTo = function (props) {
    if (!props.to)
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.children });
    return (0, jsx_runtime_1.jsx)(StyledLink, __assign({}, props));
};
exports.LinkIfTo = LinkIfTo;
exports.default = StyledLink;
