"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var styles_1 = require("@mui/material/styles");
var PREFIX = 'ModalActions';
var classes = {
    customModalActionsWrapper: "".concat(PREFIX, "-customModalActionsWrapper"),
};
var StyledDialogActions = (0, styles_1.styled)(material_1.DialogActions)(function () {
    var _a;
    return (_a = {},
        _a[".".concat(classes.customModalActionsWrapper)] = {
            padding: '1.5rem 0 0 0',
        },
        _a);
});
var ModalActions = function (_a) {
    var children = _a.children;
    return ((0, jsx_runtime_1.jsx)(StyledDialogActions, __assign({ classes: { root: classes.customModalActionsWrapper } }, { children: children })));
};
exports.default = ModalActions;
