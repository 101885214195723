"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRechargePriceData = exports.getPerPlanClientPrice = exports.getClientPriceSpecial = exports.getClientPrice = exports.getListPrice = exports.makeGetClientPrice = exports.makeGetCostAttribution = exports.getTieredPrice = void 0;
var numbers_1 = require("./numbers");
var utils_1 = require("./utils");
var getTieredPrice = function (priceDef) { return function (units) {
    var e_1, _a;
    var _b, _c;
    var total = (_c = (_b = priceDef.minimum) === null || _b === void 0 ? void 0 : _b.price) !== null && _c !== void 0 ? _c : 0;
    if (priceDef.minimum && units <= priceDef.minimum.endUnit) {
        return total;
    }
    var unitSize = priceDef.unitSize;
    try {
        for (var _d = __values(priceDef.priceTiers), _e = _d.next(); !_e.done; _e = _d.next()) {
            var priceTier = _e.value;
            if (priceTier.endUnit && priceTier.endUnit < units) {
                total += Math.ceil((priceTier.endUnit - (priceTier.startUnit - 1)) / (unitSize !== null && unitSize !== void 0 ? unitSize : 1)) * priceTier.price;
            }
            else {
                total += Math.ceil((units - (priceTier.startUnit - 1)) / (unitSize !== null && unitSize !== void 0 ? unitSize : 1)) * priceTier.price;
                break;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return total;
}; };
exports.getTieredPrice = getTieredPrice;
var makeGetCostAttribution = function (planDiscountMode, subTotal, totalUnits, totalRows) { return function (units) {
    if (planDiscountMode === 'per_unit') {
        return units / totalUnits * subTotal;
    }
    if (planDiscountMode === 'fixed_price') {
        return subTotal / totalRows;
    }
    return undefined;
}; };
exports.makeGetCostAttribution = makeGetCostAttribution;
// For Dext Billing
var makeGetClientPrice = function (planDiscount, currency) { return function (units) {
    var _a, _b;
    var fixedValue = (_a = planDiscount.fixedValue) === null || _a === void 0 ? void 0 : _a[currency];
    if (!fixedValue) {
        return undefined;
    }
    if (planDiscount.mode === 'per_unit') {
        return Math.ceil((units || 1) / ((_b = planDiscount.unitSize) !== null && _b !== void 0 ? _b : 1)) * fixedValue;
    }
    if (planDiscount.mode === 'fixed_price') {
        return fixedValue;
    }
    return undefined;
}; };
exports.makeGetClientPrice = makeGetClientPrice;
var getListPrice = function (recharge, accFirm, exchangeRates) {
    var _a, _b;
    var targetCurrency = accFirm.rechargesCurrency;
    var originalDiscount = ((_a = recharge.discount) !== null && _a !== void 0 ? _a : 0) / 100;
    var listPriceInAccFirmCurrency = (_b = recharge.listPriceInAccFirmCurrency) !== null && _b !== void 0 ? _b : recharge.amountInAccFirmCurrency / (1 - originalDiscount);
    return (0, numbers_1.roundToCents)(targetCurrency
        ? (0, utils_1.convert)(exchangeRates)(recharge.accFirmCurrency, targetCurrency)(listPriceInAccFirmCurrency)
        : listPriceInAccFirmCurrency);
};
exports.getListPrice = getListPrice;
var getClientPrice = function (rechCompVendor, recharge, accFirm, listPrice, originalDiscount, rechCust) {
    var _a, _b, _c, _d, _e, _f, _g;
    var amount = (_a = recharge.clientPriceOverride) !== null && _a !== void 0 ? _a : (_c = (_b = rechCust === null || rechCust === void 0 ? void 0 : rechCust.planPriceOverride) === null || _b === void 0 ? void 0 : _b[recharge.vendorPlanId || "".concat(recharge.vendor, "-").concat(recharge.plan)]) === null || _c === void 0 ? void 0 : _c[(_d = accFirm.rechargesCurrency) !== null && _d !== void 0 ? _d : 'AUD'];
    var hasOverride = amount !== undefined;
    if (amount === undefined && ['MYOB', 'Dext', 'QuickBooks'].includes(recharge.rechVendorId)) {
        amount = (0, exports.getClientPriceSpecial)(rechCompVendor, recharge, rechCust);
    }
    if (amount === undefined) {
        var accFirmSplit = (_g = (_e = rechCust === null || rechCust === void 0 ? void 0 : rechCust.accFirmDiscountSplit) !== null && _e !== void 0 ? _e : (_f = accFirm.discountSplitMap) === null || _f === void 0 ? void 0 : _f[recharge.vendor]) !== null && _g !== void 0 ? _g : 0;
        var clientDiscountPrecise = originalDiscount * (1 - accFirmSplit);
        var clientDiscount = Math.round(clientDiscountPrecise * 1000) / 1000;
        amount = (0, numbers_1.roundToCents)(listPrice * (1 - clientDiscount));
    }
    return { amount: amount, hasOverride: hasOverride };
};
exports.getClientPrice = getClientPrice;
var getClientPriceSpecial = function (rechCompVendor, recharge, rechCust) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (rechCompVendor.rechVendorId === 'Dext') {
        var planDiscount = recharge.vendorPlanId ? (_a = rechCompVendor.plansDiscountMap) === null || _a === void 0 ? void 0 : _a[recharge.vendorPlanId] : undefined;
        console.log('planDiscount', planDiscount);
        if (planDiscount) {
            console.log('makeGetClientPrice(planDiscount, recharge.currency)(recharge.unitCount ?? 0)', (0, exports.makeGetClientPrice)(planDiscount, recharge.currency)((_b = recharge.unitCount) !== null && _b !== void 0 ? _b : 0));
            return (0, exports.makeGetClientPrice)(planDiscount, recharge.currency)((_c = recharge.unitCount) !== null && _c !== void 0 ? _c : 0);
        }
    }
    if (rechCompVendor.discountMode === 'per_plan' && recharge.vendorPlanId && rechCompVendor.plansDiscountMap) {
        return (0, exports.getPerPlanClientPrice)(rechCompVendor, recharge, recharge.vendorPlanId);
    }
    if (rechCompVendor.discountMode === 'percent_discount') {
        var discountRate = (_d = rechCompVendor.percentForDiscount) !== null && _d !== void 0 ? _d : 0;
        return (0, numbers_1.roundToCents)(recharge.listPrice * (1 - discountRate));
    }
    if (rechCompVendor.discountMode === 'split') {
        var accFirmSplit = (_f = (_e = rechCust === null || rechCust === void 0 ? void 0 : rechCust.accFirmDiscountSplit) !== null && _e !== void 0 ? _e : rechCompVendor.percentForDiscount) !== null && _f !== void 0 ? _f : 0;
        var originalDiscount = (recharge.listPrice - recharge.amount) / recharge.listPrice;
        var discountRate = originalDiscount * (1 - accFirmSplit);
        return (0, numbers_1.roundToCents)(recharge.listPrice * (1 - discountRate));
    }
    if (rechCompVendor.discountMode === 'percent_profit') {
        return (0, numbers_1.roundToCents)(recharge.listPrice * ((_g = rechCompVendor.percentForDiscount) !== null && _g !== void 0 ? _g : 0) + recharge.amount);
    }
};
exports.getClientPriceSpecial = getClientPriceSpecial;
var getPerPlanClientPrice = function (rechCompVendor, recharge, vendorPlanId) {
    var _a, _b, _c, _d, _e, _f;
    var planDiscountSettings = (_a = rechCompVendor.plansDiscountMap) === null || _a === void 0 ? void 0 : _a[vendorPlanId];
    var clientPrice = recharge.amount;
    if (planDiscountSettings === null || planDiscountSettings === void 0 ? void 0 : planDiscountSettings.mode) {
        if (planDiscountSettings.mode === 'per_unit') {
            var perUnitprice = (_b = planDiscountSettings.fixedValue) === null || _b === void 0 ? void 0 : _b[recharge.currency];
            clientPrice = perUnitprice && perUnitprice * ((_c = recharge.unitCount) !== null && _c !== void 0 ? _c : 1);
        }
        if (planDiscountSettings.mode === 'fixed_price') {
            clientPrice = (_e = (_d = planDiscountSettings.fixedValue) === null || _d === void 0 ? void 0 : _d[recharge.currency]) !== null && _e !== void 0 ? _e : planDiscountSettings.value;
        }
        if ((planDiscountSettings === null || planDiscountSettings === void 0 ? void 0 : planDiscountSettings.mode) === 'fixed_discount') {
            clientPrice = recharge.listPrice - ((_f = planDiscountSettings.value) !== null && _f !== void 0 ? _f : 0);
        }
        if ((planDiscountSettings === null || planDiscountSettings === void 0 ? void 0 : planDiscountSettings.mode) === 'percent_discount') {
            clientPrice = planDiscountSettings.value !== undefined
                ? recharge.listPrice * (1 - planDiscountSettings.value)
                : undefined;
        }
        if (clientPrice) {
            return (0, numbers_1.roundToCents)(clientPrice);
        }
    }
};
exports.getPerPlanClientPrice = getPerPlanClientPrice;
var getRechargePriceData = function (rechCompVendor, recharge, accFirm, exchangeRates, rechCust) {
    var _a;
    var originalDiscount = ((_a = recharge.discount) !== null && _a !== void 0 ? _a : 0) / 100;
    var listPrice = (0, exports.getListPrice)(recharge, accFirm, exchangeRates);
    var _b = (0, exports.getClientPrice)(rechCompVendor, recharge, accFirm, listPrice, originalDiscount, rechCust), amount = _b.amount, hasOverride = _b.hasOverride;
    var clientDiscount = amount !== undefined && recharge.listPrice ? (recharge.listPrice - amount) / recharge.listPrice : undefined;
    return { amount: amount, listPrice: listPrice, discount: clientDiscount, hasOverride: hasOverride };
};
exports.getRechargePriceData = getRechargePriceData;
