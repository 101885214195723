"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var MenuItem = (0, material_1.styled)(material_1.MenuItem)(function (_a) {
    var _b;
    var theme = _a.theme;
    return ({
        width: '100%',
        display: 'inline-block',
        borderRadius: 0,
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        ':hover': {
            backgroundColor: theme.palette.secondary.light,
        },
        '&.Mui-disabled .MuiTypography-root': {
            color: (_b = theme.palette.neutral) === null || _b === void 0 ? void 0 : _b.main,
        },
        ':focus-visible': __assign(__assign({}, theme.focusVisibleStyles), { margin: theme.spacing(0, 0.75), width: 'calc(100% - 12px)', borderRadius: 6, paddingLeft: theme.spacing(1.25), background: theme.palette.secondary.light }),
    });
});
exports.default = MenuItem;
