"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FILTER_KEY_TO_STATUS = exports.INVOICE_STATUS_MAPPER = void 0;
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_regular_svg_icons_1 = require("@fortawesome/pro-regular-svg-icons");
var dateTime_1 = require("utils/dateTime");
exports.INVOICE_STATUS_MAPPER = {
    draft: {
        text: 'Draft',
        iconColor: '#B6C5CD',
        icon: pro_light_svg_icons_1.faFileInvoice,
        tooltip: {
            title: 'Draft invoice',
            description: 'A new invoice. The customer hasn’t been enabled for recharging yet. The status won’t change until actioned.',
        },
    },
    onHold: {
        text: 'Agreement',
        iconColor: '#46BAF1',
        icon: pro_regular_svg_icons_1.faBallotCheck,
        tooltip: function (item) { return ({
            title: 'Pending agreement acceptance',
            description: "An email was sent to the client ".concat((0, dateTime_1.formatRelativeMidSentence)(item.date), " to accept the recharge agreement and to add\n      a payment option."),
        }); },
    },
    scheduledForSending: {
        text: 'Scheduled',
        iconColor: '#46BAF1',
        icon: pro_light_svg_icons_1.faFileInvoiceDollar,
        tooltip: function (item) { return ({
            title: 'Scheduled for sending',
            description: "This is a draft invoice. It will be finalised ".concat((0, dateTime_1.formatRelativeMidSentence)(item.date), "\n      (next billing date). New charges will be automatically added to this invoice."),
        }); },
    },
    sent: {
        text: 'Sent',
        iconColor: '#02AD6B',
        icon: pro_light_svg_icons_1.faMemoCircleCheck,
        tooltip: { title: 'Invoice sent', description: 'The invoice was sent to the client and is pending payment.' },
    },
    paymentInProgress: {
        text: 'Paying',
        iconColor: '#02AD6B',
        icon: pro_light_svg_icons_1.faCircleDollarToSlot,
        tooltip: {
            title: 'Payment in progress',
            description: 'The client is on auto-charge and Stripe is processing the payment of this invoice.',
        },
    },
    paid: {
        text: 'Paid',
        iconColor: '#02AD6B',
        icon: pro_regular_svg_icons_1.faCircleDollar,
        tooltip: function (item) {
            var _a;
            return ({
                title: 'Invoice paid',
                description: "The payment of this invoice was collected successfully by Stripe".concat(' ', "\n      ").concat(((_a = item.statusTransitions) === null || _a === void 0 ? void 0 : _a.paidAt) ? (0, dateTime_1.formatRelativeMidSentence)(item.statusTransitions.paidAt) : '', "."),
            });
        },
    },
    failed: {
        text: 'Payment failed',
        iconColor: '#EC7069',
        icon: pro_regular_svg_icons_1.faCircleXmark,
        tooltip: function (item) {
            var _a;
            return ({
                title: 'Payment attempt failed',
                description: "We tried unsuccessfully to collect payment for this invoice.\n      ".concat(((_a = item.statusTransitions) === null || _a === void 0 ? void 0 : _a.failedAt) && " Last attempt was on ".concat((0, dateTime_1.formatRelativeMidSentence)(item.statusTransitions.failedAt[0])), "."),
            });
        },
    },
    voided: {
        text: 'Void',
        iconColor: '#EC7069',
        icon: pro_regular_svg_icons_1.faCircleXmark,
        tooltip: function (item) {
            var _a;
            return ({
                title: 'Voided',
                description: "The invoice was voided\n      ".concat(((_a = item.statusTransitions) === null || _a === void 0 ? void 0 : _a.voidedAt) ? "".concat((0, dateTime_1.formatRelativeMidSentence)(item.statusTransitions.voidedAt), ".") : '', "\n       and no payment was collected."),
            });
        },
    },
    // cardExpired: { text: 'Card expired', iconColor: '#EC7069', icon: faSubtitlesSlash },
};
exports.FILTER_KEY_TO_STATUS = {
    draft: 'draft',
    scheduled: 'scheduledForSending',
    agreement: 'onHold',
    sent: 'sent',
    paying: 'paymentInProgress',
    paid: 'paid',
    failed: 'failed',
    void: 'voided',
};
