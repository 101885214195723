"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var faCircleXmark_1 = require("@fortawesome/pro-light-svg-icons/faCircleXmark");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var material_1 = require("@mui/material");
var ModalIcon = function (_a) {
    var _b;
    var _c = _a.status, status = _c === void 0 ? 'success' : _c, icon = _a.icon;
    var theme = (0, material_1.useTheme)();
    var displayIcon = icon !== null && icon !== void 0 ? icon : (status === 'error' ? faCircleXmark_1.faCircleXmark : '/GradientCheckmarkIcon.svg');
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [typeof displayIcon === 'string' && (0, jsx_runtime_1.jsx)("img", { src: displayIcon, alt: "".concat(status, " img"), height: "64", width: "64" }), typeof displayIcon === 'object' && ((0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: displayIcon, size: "4x", color: status === 'error' ? (_b = theme.palette.red) === null || _b === void 0 ? void 0 : _b.main : theme.palette.primary.main }))] }));
};
exports.default = ModalIcon;
